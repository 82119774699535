import React from "react";
import { Link } from "gatsby";
import { format } from "date-fns";

import { getBlogUrl } from "../lib/helpers";

function BlogPostList({ nodes = [] }) {
  return (
    <ul className="dark:text-gray-200">
      {nodes.map((node) => (
        <li className="my-12 ml-0" key={node.id}>
          <Link to={getBlogUrl(node.publishedAt, node.slug.current)}>
            <h2 className="text-3xl font-sans semi-condensed font-bold text-gray-800 dark:text-gray-300">
              {node.title}
            </h2>
            <div className="uppercase font-sans text-gray-400 text-xs tracking-wider mt-1 mb-3">
              {format(new Date(node.publishedAt), "MMMM Mo, yyyy")}
            </div>
            {node.excerpt && <p className="mt-2 text-lg">{node.excerpt}</p>}
          </Link>
        </li>
      ))}
    </ul>
  );
}

export default BlogPostList;
