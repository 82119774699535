import React from "react";
import { graphql } from "gatsby";
import { isFuture } from "date-fns";

import BlogPostList from "../components/blog-post-list";
import SEO from "../components/seo";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query BlogPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    posts: allSanityPost(
      limit: 6
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            ...SanityImage
            alt
          }
          title
          excerpt
          slug {
            current
          }
        }
      }
    }
  }
`;

const BlogPage = ({ data = {} }) => {
  const postNodes = data.posts
    ? data.posts.edges
        .map((edge) => edge.node)
        .filter(
          (post) => post?.slug?.current && !isFuture(new Date(post.publishedAt))
        )
    : [];

  return (
    <>
      <SEO title={"Blog"} />
      <div className="prose dark:prose-invert prose-lg md:pt-12">
        <h1>Blog</h1>
      </div>
      {postNodes && <BlogPostList nodes={postNodes} />}
    </>
  );
};

export default BlogPage;
